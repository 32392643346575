import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    cost_type_id : {
        show: true,
        title: i18n.t('message.costType'),
        sortable: true,
        column: 'cost_type_id'
    },
    date : {
        show: true,
        title: i18n.t('message.date'),
        sortable: true,
        column: 'date'
    },
    payment_type_id : {
        show: true,
        title: i18n.t('message.paymentType'),
        sortable: true,
        column: 'payment_type_id'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    currency_rate : {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'currency_rate'
    },
    currency : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency'
    },
    bank_account_id : {
        show: true,
        title: i18n.t('message.bankAccount'),
        sortable: true,
        column: 'bank_account_id'
    },
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
