<template>
    <div class="builder__box">
        <div class="builder__into" :class="mode ? 'builder__intoday' : 'builder__intonight'">
            <div>
                <div class="builder__title">
                    <h5 :class="mode ? 'text__day' : 'text__night'">
                        <!-- {{ $t("message.display_and_wallpaper") }}  -->
                        НАСТРОЙКА ТЕМЫ
                    </h5>
                </div>
                <span class="builder__text mb-2" :class="mode ? 'text__day' : 'text__night'">Расположения</span>
                <div class="builder__mode mb-3" :class="mode ? 'builder__modeday' : 'builder__modenight'">
                    <div class="shortcuts__list">
                        <el-radio-group v-model="second" class="shortcuts__odd">
                            <li class="shortcuts__item" v-for="(item, index) in position" :key="item.id">
                                <el-radio :label="item.id">
                                    <figure class="builder__image">
                                        <img v-if="index == 0" :src="item.src" alt="new version">
                                        <img v-else src="/img/star-v.png" alt="old version">
                                    </figure>
                                    <figcaption class="shortcuts__name" :class="mode ? 'text__day' : 'text__night'">
                                        {{ item.name }}
                                    </figcaption>
                                </el-radio>
                            </li>
                        </el-radio-group>
                    </div>
                </div>

                <span v-if="second == 1" class="builder__text mb-2" :class="mode ? 'text__day' : 'text__night'">
                    <!-- {{
                $t("message.dynamic_wallpaper")
                }} -->
                    Динамические обои
                </span>
                <div v-if="second == 1" class="builder__wall mb-3" v-loading="isLoaded"
                    :class="mode ? 'builder__modeday' : 'builder__modenight'">
                    <el-radio-group v-model="third" class="builder-list">
                        <li v-for="back in wallpapers" :key="back.id" class="builder-list__item minge-style">
                            <el-radio :label="back">
                                <img :src="back.resize" alt="" @load="onImgLoad">
                            </el-radio>
                        </li>
                    </el-radio-group>
                </div>
                <span v-show="error" class="red__error"> {{ $t("message.please_select") }}</span>
            </div>
            <div slot="footer" class="dialog__footer pb-2">
                <el-button class="btn__full" type="info" :plain="mode ? true : false" @click="close()">{{
                    $t("message.close")
                }}</el-button>
                <el-button @click="submitHandler()" class="btn__full" type="success" :plain="mode ? true : false">{{
                    $t("message.save")
                }}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import drawer from '../../utils/mixins/drawer'
import { mapGetters } from "vuex";

export default {
    mixins: [drawer],
    props: [],
    data: () => ({
        first: false,
        error: false,
        second: 1,
        isLoaded: true,
        automatic: false,
        third: '',
        position: [
            {
                id: 1,
                src: '/img/newversion.svg',
                name: 'Новая версия'
            },
            {
                id: 2,
                src: '/img/oldversion.svg',
                name: 'Старая версия'
            }
        ],
        wallpapers: [
            {
                id: 115,
                check: false,
                url: '/img/background/without.jpg',
                resize: '/img/background/without-min.png',
                name: 'White',
                mode: true
            },
            {
                id: 66,
                check: false,
                url: '/img/background/22.png',
                resize: '/img/background/22-min.png',
                name: 'Building',
                mode: true
            },
            {
                id: 44,
                check: false,
                url: '/img/background/6.jpg',
                resize: '/img/background/4-min.png',
                name: 'Tree',
                mode: true
            },
            {
                id: 55,
                check: true,
                url: '/img/background/33.jpg',
                resize: '/img/background/33-min.png',
                name: 'Crocket',
                mode: true
            },
            {
                id: 113,
                check: false,
                url: '/img/background/line.jpg',
                resize: '/img/background/line-min.png',
                name: 'Line',
                mode: true
            },

            {
                id: 114,
                check: false,
                url: '/img/background/blur.jpg',
                resize: '/img/background/blur-min.png',
                name: 'Music',
                mode: true
            },
            {
                id: 116,
                check: false,
                url: '/img/background/black.jpg',
                resize: '/img/background/black-min.png',
                name: 'Black',
                mode: false
            },
            {
                id: 77,
                check: false,
                url: '/img/background/10.jpg',
                resize: '/img/background/10-min.png',
                name: 'Buble',
                mode: false
            },
            {
                id: 88,
                check: false,
                url: '/img/background/8.jpg',
                resize: '/img/background/8-min.png',
                name: 'Camera',
                mode: false
            },
            {
                id: 99,
                check: false,
                url: '/img/background/9.jpg',
                resize: '/img/background/9-min.png',
                name: 'Virus',
                mode: false
            },
            {
                id: 111,
                check: false,
                url: '/img/background/pyramid.jpg',
                resize: '/img/background/pyramid-min.png',
                name: 'Spiral',
                mode: false
            },
            {
                id: 112,
                check: false,
                url: '/img/background/renato.jpg',
                resize: '/img/background/renato-min.png',
                name: 'Wave',
                mode: false
            },


        ]
    }),
    mounted() {
    },
    methods: {
        beforeClose(done) {
            this.customBuilder = false
        },
        afterOpen() {
            this.first = this.mode
            this.second = this.radio
        },
        onImgLoad() {
            setTimeout(() => this.isLoaded = false, 500);

        },
        submitHandler() {
            const info = {
                layout: this.second,
                mode: this.first,
                background: this.third,
            }
            if (this.second == 1) {
                if (info.background) {
                    this.$store.commit('builderSpan', info)
                    this.close()
                } else {
                    this.error = true
                }
            } else if (this.second == 2) {
                this.$store.commit('builderSpan', info)
                this.close()
            } else {
                this.$store.commit('builderSpan', info)
                this.close()
            }
            // this.$router.go(this.$router.currentRoute)

        }
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            oldVersion: "OLDVERSION",
            leftVersion: "LEFTVERSION",
            openVersion: "OPENVERSION",
            background: "BACKGROUND",
            radio: 'RADIO'
        }),
    }
}
</script>

<style lang="scss">
.el-radio {
    margin: 0 !important;
    width: 100%;
    // height: 100%;
    border: 1.5px solid #fff7ed;
    border-radius: 6px;
    transition: all .2s ease-in-out;
    overflow: hidden;

    &:hover {
        border-color: #93c5fd;
    }

    // el-radio__label
    &__label {
        margin: 0 !important;
        padding: 0 !important;
        text-align: center;

        img {
            width: 100%;
            object-fit: cover;
            vertical-align: middle;
        }
    }

    // el-radio__input
    &__input {
        display: none !important;
    }

    &.is-checked {
        border-color: #93c5fd;
    }
}
</style>

<style lang="scss" scoped>
.builder__box {
    height: 100%;
}

.builder__title h5 {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
}

.btn__full {
    width: 100%;
}

.builder__mode {
    padding: 10px 15px;
    border-radius: 0.475rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-top: 10px;
}

.builder__modenight {
    background: #323248 !important;
}

.builder__modeday {
    background: #fff !important;
}

.builder__wall {
    background: #fff;
    padding: 10px 15px;
    border-radius: 0.475rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-height: 100px;
    max-height: 46vh;
    overflow-y: auto;
    margin-top: 10px;
}

.builder__title {
    margin: -20px 0;
}

.mode__shadowday {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mode__shadownight {
    box-shadow: none;
}

.mode__shadownight img {
    box-shadow: none !important;
}

.red__error {
    color: red;
}

.builder__wall::-webkit-scrollbar {
    width: 8px;
}

.builder__wall {
    scrollbar-width: thin;
    scrollbar-color: #ebeef5;
}

.builder__wall::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
}

.builder__wall::-webkit-scrollbar-thumb {
    background-color: #ebeef5;
    border-radius: 10px;
    border: 2px solid #fff0;
}

.builder__wall:hover::-webkit-scrollbar-thumb {
    background-color: #d1d7e4;
    border-radius: 10px;
    border: 2px solid #fff0;
}

.text__night {
    color: #fff;
}

.text__day {
    color: #000;
}

.builder__into {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.builder__intoday {
    background: #fff;
}

.builder__intonight {
    background: #100e1d;
}

.dialog__footer {
    display: flex;
    flex-direction: row;
}

.btn__cancel {
    width: 100%;
    margin-right: 5px;
    background: #ebb563;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #ebb563;
    padding: 8px 0;
}

.btn__submit {
    width: 100%;
    margin-left: 5px;
    background: #0071e3;
    color: #fff;
    font-weight: 600;
    border-radius: 10px;
    border: 1px solid #0071e3;
    padding: 8px 0;
}

.btn__submit:hover {
    background: #0077ed;
}

.builder__options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.dialog-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
}

.shortcuts__input {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 50% !important;
    margin-right: 10px;
}

.shortcuts__input:focus-visible {
    outline-width: 0;
}

.shortcuts__name {
    padding: 10px 0;
    font-size: 14px;
}

.shortcuts__odd {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.shortcuts__item {
    width: 100%;
    list-style: none;
    // height: 100px;
}

.shortcuts__item2 {
    display: flex;
    align-items: center;
    margin: 0px 0;
    width: 47%;
}


.builder__svg {
    width: 100%;
    height: 100%;
}

.builder__row {
    width: 33%;
}

.builder__into {
    padding: 20px 15px;
}

.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
}

.builder__item span {
    font-size: 14px;
}

.shortcut__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 150px;
}

.shortcut__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 10px 10px;
}

.shortcut__footer h5 {
    margin: 0;
    line-height: 16px;
    font-size: 14.95px;
    font-weight: 500;
    margin-top: 5px;
}

.shortcut__footer span {
    font-weight: 600;
    font-size: 17.55px;
}

.shortcut__view i {
    color: #50cd89;
    background-color: #2d3e35;
    padding: 4px 8px;
    border-radius: 20%;
}

.shortcut__viewday i {
    color: #fff;
    background-color: #50cd89;
    padding: 4px 8px;
    border-radius: 20%;

}

.shortcuts__image {
    border: 1px dashed #323248;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12.5px;
}

.shortcuts__image img {
    width: 35px;
    height: 35px;
    object-fit: contain;
}

.drawer__back div div {
    background-color: #ffffff !important;
}

.shortcuts__title span {
    color: #fff;
    font-size: 19.5px;
}

.builder__image {
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff7ed87;
    margin-bottom: 0;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
}

.builder__image img {
    width: 100%;
    object-fit: fill !important;
    padding: 0px;
    margin: 0px;

    &:last-child {
        height: 15vh;
    }
}

.mode__image {
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.mode__image img {
    width: auto;
    height: 100px;
    border-radius: 0.475rem;
    object-fit: contain;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mode__image1 {
    width: 100%;
    height: 80px;
    display: flex;
    object-fit: contain;
    border-radius: 0.475rem;

}

.mode__image2 {
    width: 100%;
    height: 120px;
    display: flex;
    object-fit: contain;
}

.mode__image2 img {
    width: 100%;
    height: 100%;
    border-radius: 0.475rem;
    object-fit: cover;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mode__image1 img {
    width: 100%;
    height: 100%;
    border-radius: 0.475rem;
    object-fit: cover;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.builder-list {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    flex-wrap: wrap;
    list-style: none;

    // builder-list__item
    &__item {
        width: 100%;
        height: 100px;
    }
}


.shortcuts__label1 {
    overflow: hidden;
    cursor: pointer;
    width: 100%;
}

.builder {
    position: fixed;
    right: 0;
    margin-right: -39px !important;
    bottom: 50%;
    top: 50%;
    z-index: 99;
}

.builder__item {
    display: flex;
    flex-direction: row;
    transform: rotate(90deg);
    background-color: rgba(0, 0, 0, 0.33);
    border-color: transparent;
    padding: 2px 10px;
    cursor: pointer;
    border-radius: 0 0 0.45rem 0.45rem;
}

.builder__item i {
    transform: rotate(-90deg);
    font-size: 14px;
    color: #fff;
}

.builder__item:hover {
    color: #fff;
}

.builder__item span {
    font-size: 14px;
    color: #fff;
}

.builder__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0;
}

.flex__between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.builder__text {
    font-size: 14px;
    font-weight: 600;
}

.minge-style .el-radio,
.minge-style img {
    height: 100px;
}

.minge-style .el-radio.is-checked {
    border: 4px solid #93c5fd;
}
</style>
