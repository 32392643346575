<template>
    <i @click="$emit('c-click')" class="el-icon-refresh cursor-pointer"></i>
</template>
<script>
    export default {
    }
</script>
<style>
.cursor-pointer{
	cursor: pointer;
	margin-left: 5px;
}
</style>
