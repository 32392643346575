<template>
   <div>
		<el-input 
			type="number"
			:value="value" 
			@input="dispatch"
			@change="Cchange"
			:size="size"
			:style="customStyle"
			:class="className"
			:max="maxValue"
			:min="minValue"
		>
		</el-input>
   </div>
</template>

<script>
export default {
	props:{
		inputValue: {
			default: ''
		},
		size: {
			default: 'small'
		},
		className: {
			default: 'w-100'
		},
		customStyle: {
			default: ''
		},
		maxValue: {
			default: ''
		},
		minValue: {
			default: ''
		}
	},
	watch:{
		inputValue:{
			handler: function (){
				this.value = this.inputValue;
			},
			deep: true,
			immediate: true
		}
	},
	data() {
		return {
			value: null
		}
	},
	methods: {
		dispatch(e){
			this.$emit('input', e);
			this.value = e;
		},
		Cchange(e){
			this.$emit('c-change', e);
			this.value = e;
		},
	}

}
</script>

<style>

</style>