<template>
  <div id="app" :class="[ leftVersion ? (mode ? 'l-modal-style' : 'd-modal-style') : '']">
    <div class="app-wrap d-flex f-column">
      <component v-show="!lockStatus" :is="layout">
        <router-view />
      </component>
      <twostep v-if="lockStatus" :show-lock="lockStatus"></twostep>
    </div>
  </div>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";
import LockScreenMixin from './utils/mixins/LockScreenMixin'
import twostep from './views/twostep/index.vue'
import {mapGetters} from 'vuex';
export default {
  mixins: [LockScreenMixin],
  mounted(){
    let date = new Date()
    let today = `${date.getDate()} ${(date.getMonth()+1)} ${date.getFullYear()}`;
    this.NEW_DAY_CHECK({date: today});
  },
  watch: {
      idle(newValue) {
          if (!this.disable) {
              if(this.idle) {
                  this.lockUser()
              }
          } else {

          }
      }
  },
  computed: {
    ...mapGetters({
        timer: 'getLockTimer',
        idle: 'getLockBool',
        disable: 'getLockDisable',
        mode: "MODE"
    }),
    leftVersion () {
      return this.$store.getters.LEFTVERSION
    },
		isIdle() {
        return this.$store.state.idleVue.isIdle && this.$route.path.split('/')[2] != 'login';
    },
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },

  },
  methods: {
    lockUser(){
        // set lock password if it's not set
        if(this.lockPassword === ''){
            this.showSetLockPasswordModal = true;
        } else {
            this.TRIGGER_LOCK({ status: true });
        }
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
    LockScreenMixin,
    twostep
  },
};
</script>

<style lang="scss">
// Color
.d-modal-style~.el-drawer__wrapper .el-drawer__body,
.d-modal-style{
    // & ~.el-drawer__wrapper .el-drawer__body{
    //     background-color: #080c24 !important;
    //     .timeline-items__right, .step-block-deals-from, .el-steps--simple,
    //     .modal-cardi-mi .el-card{
    //     border: 1px dashed #2a2f34;
    //     border-left: 5px solid#0d67c9;
    //     background-color: #080c24 !important;
    //     }
    // }
    .heap-status,
    .el-divider__text,
    .h-icone, .mobil-top-header i, .color-o, .el-step__title,
    .breadcrumb__item, .breadcrumb__item i, .color-w,
    .el-submenu__title i,
    .el-submenu__title,
    .font-bold,
    .el-switch__label,
    .el-checkbox,
    .el-tab-pane,
    // .gc-card span,
    h1, h2, h3, h4, h5, h6, p, b,
    .user-info,
    .dashboard__info,
    .dashboard__margin,
    .el-tabs__item,
    .el-collapse-item__header,
    .input--label,
    .el-dialog__title,
    .el-input__inner:not(:placeholder-shown),
    .el-pagination__total,
    .apexcharts-title-text,
    .apexcharts-legend-text,
    .block-titles,
    .apexcharts-text,
    .modal-title-my span,
    .el-form-item__label, .color_th,
    .name-tesk,
    .message-title,
    .ava-title,  .f-12, .ico.ico_money,
    .large--title{
        color: #fff !important;
        fill: #fff;
    }

    // bg color
    .el-divider__text,
    .users-dropdown-items:hover,
    .users-dropdown-items.active,
    .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active,
    .el-collapse-item__header,
    .el-collapse-item__wrap,
    // .card__tablenight,
    .el-dialog__body,
    .app-modal__box,
    .change-data-card,
    .roles .el-tree,
    .modal-cardi-mi .el-card,
    .el-drawer,
    .person-project,
    .template-blocks{
        background-color: #080c24 !important;
    }

    .modal-footer-buts,
    .el-tabs--border-card>.el-tabs__header,
    .el-dialog__header,
    .el-dialog__footer,
    .el-input-number__increase,
    .el-input-number__decrease,
    .app-modal__header,
    .my-new-card-style,
    .modal-header{
      border-bottom: 1px dashed #2a2f34;
      background-color: #0e2c46 !important;
    }

    // border Input elemen
    .mm-updating-back-color,
    .mm-mini-comment-container,
    .gc-card,
    .el-tag.el-tag--info,
    .gc-country-input,
    .responsible-users-dropdown,
    .responsible-block-dropdown,
    .responsible-block,
    .el-upload--picture-card,
    .el-tree,
    .timeline-textarea,
    .content_header .el-button,
    .el-input__inner,
    .tom,
    .el-input-group__append,
    .el-drawer__body .el-input__inner,
    .el-textarea__inner
    {
        border: 1px dashed #2a5376ad !important;
        border: 1px dashed #274156 !important;
        background-color: #0e2c4685 !important;
        color: #ced4da;
    }

    // Table of contents
    .el-tabs--border-card,
    .el-checkbox__inner,
    .table-my-code,
    .table-my-code td,
    .table-my-code th,
    .table__mynight,
    .table__mynight th,
    .table__mynight td,
    .style-home .el-card,
    .table,
    .table th,
    .table td{
      border: 1px dashed #2a2f34 !important;
      background-color: #080c24 !important;
      color: #ced4da;
    }
     .mm-hover-show,
     .mm-hover-parent:hover .mm-hover-show{
      background-color: #080c24!important;
      border: 1px dashed #2a2f34 !important;
    }
    // element card
    .product-add,
    .timeline-items__right, .step-block-deals-from, .el-steps--simple,
    .modal-cardi-mi .el-card{
      border: 1px dashed #2a2f34;
      border-left: 5px solid#0d67c9;
      background-color: #080c24 !important;

    }
    .home-percentage,
    .el-pager .number,
    .el-pagination button,
    .el-pagination button:disabled{
        background-color: #0e2c4685;
        border: 1px dashed #2a2f34 ;
        border-radius: 5px;
    }
    .proj_color {
        border-color: transparent transparent #080c24 #080c24;
    }
    .my-new-card-style .button-style-my{
        color: #fff;
    }
    .el-tree-node__content:hover{
      background-color: #0e2c46 !important;
    }
    .el-input-number__increase{
      border-left: 1px dashed #2a2f34 ;
    }
    .el-input-number__decrease{
      border-right: 1px dashed #2a2f34 ;
    }
    .collapseno-border .el-collapse-item__wrap{
      border: none;
    }
    .collapseno-border .el-collapse-item__wrap hr{
      display: none;
    }
    .el-date-editor .el-range-input{
      background-color: initial;
    }
    .el-table td, .el-table th.is-leaf {
      border-bottom: 1px dashed #2a2f34 ;
    }
    .el-table__empty-block{
      background-color: #080c24 !important;
    }
    .d-modal-style .el-checkbox__inner{
        border-color: #696c6e;
    }
    .itme-person-project .el-checkbox{
        border: 1px dashed #2a2f34 !important;
    }
    .el-table,
    .el-table th,
    .el-table tr{
      background-color: #080c24 !important;
      border: 1px dashed #2a2f34 ;

    }
    .el-table--border::after,
    .el-table--group::after,
    .el-table::before{
      display: none;
    }
    .el-step__head.is-process{
        color: #fff;
        border-color: #fff;
    }
    .tt-name>span {
        color: #000 !important;
    }
}
.l-modal-style{
    .mm-hover-show,
    .mm-hover-parent:hover .mm-hover-show,
    .el-pager .number,
    .el-pagination button,
    .el-pagination button:disabled{
      background-color: #ffffff;
      border: 1px solid #dee2e6;
      border-radius: 5px;
    }
    .el-pager .number.active{
      border: 1px solid #dee2e6;; ;
      background: #0d67c9;
      color: #ced4da;
      // border-radius: 5px;
    }
    
    .data-deal-task-start1{
      color: #080c24;
    }
    .name-tesk {
        color: #7e7e7e;
    }
}
</style>
