import { index, inventory, inventoryForFilter, show, passwordResetValidation, usersDeals, search, store, 
    update, destroy, checkEmailConnection, deleteUserEmail, userEmailInventory, getUserPermissionTypes, checkUserData, getUserTelegramStatuses,
	 storeSettings} from "@/api/hr/users";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.users);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.users);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.users);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getUserTelegramStatuses({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getUserTelegramStatuses(params).then(res => {
                commit("SET_USER_TELEGRAM_STATUSES", res.data.result.data.statuses);
                resolve(res.data.result);
            }).catch(err => {
                reject(err);
            })
        })
    },

    inventoryForFilter({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventoryForFilter(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.users);
                resolve(res.data.result.data);
            }).catch(err => {
                reject(err)
            })
        })
    },

    search({ commit }, text = '') {
        return new Promise((resolve, reject) => {
            search(text).then(res => {
                commit("SET_SEARCH", res.data.result.data.users);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },


    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.user);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    checkEmailConnection({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            checkEmailConnection(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteUserEmail({ commit }, id) {
        return new Promise((resolve, reject) => {
            deleteUserEmail(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    userEmailInventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            userEmailInventory(params).then(res => {
                commit("SET_USER_EMAIL_INVENTORY", res.data.result.data);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    usersDeals({ commit }, data) {
        return new Promise((resolve, reject) => {
            usersDeals(data)
                .then(res => {
                    commit('SET_USERS_DEALS', res.data.result.data.users);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

	 storeSettings({ commit }, data) {
		return new Promise((resolve, reject) => {
			storeSettings(data)
				  .then(res => {
						resolve(res);
				  })
				  .catch(err => {
						reject(err);
				  });
		});
  },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    passwordResetValidation({ commit }, data) {
        return new Promise((resolve, reject) => {
            passwordResetValidation(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    userPermissionTypes({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getUserPermissionTypes(params).then(res => {
                commit("SET_PERMISSION_TYPES", res.data.result.data);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    checkUserData({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            checkUserData(params)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
