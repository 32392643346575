import { i18n } from "@/utils/i18n";

export const columns = {
    id: {
        show: true,
        title: i18n.t("message.n"),
        sortable: true,
        column: "id"
    },
    name: {
        show: true,
        title: i18n.t("message.nameFull"),
        sortable: true,
        column: "name"
    },
    first_name: {
        show: true,
        title: i18n.t("message.first_name"),
        sortable: true,
        column: "first_name"
    },
    surname: {
        show: true,
        title: i18n.t("message.surname"),
        sortable: true,
        column: "surname"
    },
    patronymic: {
        show: false,
        title: i18n.t("message.patronymic"),
        sortable: true,
        column: "patronymic"
    },
    phone: {
        show: true,
        title: i18n.t("message.phone"),
        sortable: true,
        column: "phone"
    },
    email: {
        show: false,
        title: i18n.t("message.email"),
        sortable: true,
        column: "email"
    },
    status: {
        show: false,
        title: i18n.t("message.status"),
        sortable: true,
        column: "status"
    },
    role_id: {
        show: true,
        title: i18n.t("message.role"),
        sortable: true,
        column: "role_id"
    },
    telegram_chat_id: {
        show: true,
        title: i18n.t("message.telegram_chat_id"),
        sortable: true,
        column: "telegram_chat_id"
    },
    user_telegram_status: {
        show: true,
        title: i18n.t("message.user_telegram_status"),
        sortable: true,
        column: "user_telegram_status"
    },
	 department: {
        show: true,
        title: i18n.t("message.department"),
        sortable: true,
        column: "department"
    },

    sip_account: {
        show: true,
        title: i18n.t("message.sip_number"),
        sortable: true,
        column: "sip_account"
    },
    // pin_code: {
    //     show: false,
    //     title: "Пин код",
    //     sortable: true,
    //     column: "pin_code"
    // },
    // is_employee: {
    //     show: true,
    //     title: "Сотрудник",
    //     sortable: true,
    //     column: "is_employee"
    // },
    created_at: {
        show: false,
        title: i18n.t("message.created_at"),
        sortable: true,
        column: "created_at"
    },
    updated_at: {
        show: false,
        title: i18n.t("message.updated_at"),
        sortable: true,
        column: "updated_at"
    },
    settings: {
        show: true,
        title: i18n.t("message.settings"),
        sortable: false,
        column: "settings"
    }
};
