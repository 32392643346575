export const tr_messages = {
yes: 'Evet',
no: 'Hayır',
n: "№",
total_amount: "Toplam tutar",
deals: "Fırsatlar",
deal: "Anlaşma",
create: "Oluştur",
create_board: "Beyaz tahta oluştur",
update_board: "Tahtayı düzenle",
update: "Düzenle",
delete: "Sil",
board_name: "Yönetim Kurulu adı",
The_field_must_not_be_empty: "Alanlar boş olmamalı",
header_color: "Renk başlıkları",
New_header_color: "Yeni renk başlıkları",
See_board_name: "Tahtanın adını görün",
New_board_name: "Yeni yönetim kurulu adı",
company: "Müşterinin şirketi",
companies: "Müşteri şirketleri",
client: "Müşteri",
user: "Kullanıcı",
name: "İsim",
first_name: "Ad",
surname: "Soyadı",
patronymic: "Göbek adı",
phone: "Telefon",
gender: "Cinsiyet",
email: "E-posta",
role_id: "Rol",
nameFull: "İsim",
new_source: "Yeni Kaynak",
save: "Kaydet",
close: "Kapat",
search: "Ara",
source: "Kaynak",
sources: "Kaynaklar",
text: "Mesaj",
text_t: "Metin",
type_id: "Tür",    
settings: "Ayarlar",
color: "Renk",
colors: "Renkler",
date: "Tarih",
status: "Durum",
count_product: "Miktar",
total_price: "Miktar",
download: "İndirmek",
filial: "Kullanıcı şirketi",
currency: "Para birimi",
currencies: "Para birimleri",
begin_date: "Başlangıç tarihi",
end_date: "Bitiş tarihi",
without_finish_date: "Bitiş tarihi yok",
deal_type: "Anlaşma türü",
dealStage: "Sahne",
comment: "Yorum",
dealType: "Anlaşma türü",
created_at: "Oluşturulma tarihi",
updated_at: "Değiştirilme tarihi",
symbol: "Sembol",
edit_m: "{m} öğesini düzenle",
new_m: "Yeni {m}",
mfo: "İFC",
okonx: "UEDS",
oked: "EFDÇS",
rkp_nds: "TTO KDV",
work_phone: "İş telefonu",
work_place: " İş yeri",
legal_address:"Yasal adres",
landmark: "Dönüm Noktası (Yasal Adres)",
residence_palace:"Kayıt adresi",
costType: 'Akış tipi',
costTypes: 'Harcama türleri',
dealTypes: 'Anlaşma türü',
measurements: 'Ölçümler',
dealStages: "Aşama",
// mi
patronymic:'Patronimik',
surname: 'Soyadı',
pin_code: 'Pın kodu',
is_employee:'Çalışan',
//end
nameGiven: "İsim",
nameFull: "İsim",
requirements: "Gereksinimler",
requirements_y: "Talepler",
reason: "Sebep",
reason_y: "Sebepler",
priority: "Öncelik",
priority_y: " Öncelikler",
position:"Pozisyon",
position_p:"Pozisyonlar",
graphicType: "Grafik türü",
graphicType_p: "Grafik türleri",
company: "Müşterinin şirketi",
currency: "Para birimi",
currency_y: "Para birimi",
filial: "Kullanıcı şirketi",
filter: "Filtre",
total_working_day: "Çalışma haftası",
graphic: "Çalışma takvimi",
language_graphic_type: "Lütfen bir başlık girin",
employmentType : "İstihdam türü",
province: "Şehir",
region:"Şehir",
region_: "Bölge",
vacancy:"İşler",
last_name:"Soyadı",
middle_name:"Orta isim",
date_of_birth:"Doğum tarihi",
phone:"Telefon",
email:"E-posta",
status:"Durum",
role:"Rol",
phone_number_y:"Tel numarası",
summary: "Özet",
UploadFile: "Dosyayı yükle",
UploadFileText: " 500 kb'den küçük jpg / png dosyaları ",
serial_number: "Seri numarası",
ip_address: "IP adresi",
device:"Cihazlar",
device_y:"Cihaz",
income: "Gelir düzeyi",
work_type: "Çalışan Durumu",
district_id:"Mahalle ",
district:"Mahalle",
nation:"Ulus",
nation_y:"Ulus",
code: "Kod",
okurs: "Ters kurs",
profile: "Profil",
dateAdoption: "Kabul tarihi",
staffTest: "Deneme ",
operating: "Oyunculuk",
dismissed: "Kovuldu",
save: "Kaydet",
close: "Kapat",
candidates: "Adaylar",
find: "ara",
daily: "Günlük",
from: "Kimden",
before: "Önce",
dinner: "Öğle Yemeği",
users: "Kullanıcılar",
user: "Kullanıcı",
user_id: "Kullanıcı",
password: "Şifre",
new_password: "Yeni Şifre",
current_password: "Geçerli Parola",
confirm_password: "Şifreyi tekrarla",
finance: "Finans",
client: "Müşteri",
money_amount: "Miktar",
access_rights: "Erişim hakları",
active: "Aktif",
is_active: "Aktif",
inactive: "Aktif değil",
working_hours_report: "Çalışma saati raporu",
age: "Yaş",
nv_monitoring: "Nv'nin izlenmesi",
o_salary: "Ücret ödemesi",
new_incoming_payment: "Yeni gelen ödeme",
incoming: "Gelen",
outgoing: "Giden",
employees: "Çalışanlar",
paymentTypes: "Ödeme türü",
amount: "Miktar",
check: "Hesap",
check_a: "Hesaplar",
salary_type: "Maaş türü",
salary: "Maaş",
bank: "Banka",
checking_account: "Çek hesabı",
expiration_date: "Bitiş tarihi",
comment: "Yorum",
overtime: "Fazla Mesai",
total: "Toplam:",
being_late: "Gecikmeler",
other_penalties: "Diğer cezalar",
penalties: "Cezalar",
bonuses: "Bonuslar",
on_hands: "Eller serbest",
passport_number: "Pasaport serisi",
passport_issued_by: "Pasaportun kime verildiği",
passport_valid_until: "Pasaportun geçerlilik süresi",
inn: "vergi mükellefi kimlik numarası",
job: "İş",
dashboard: "Dashboard",
hiring_date: "İşe alım tarihi",
manager: "Yönetici",
allow_th_employee_to_access_the_system: "Çalışanın sisteme erişmesine izin ver.",
add_candidates: "Aday ekle",
description: "Açıklama",
photo: "Fotoğraf",
mini: "mini",
max: "max",
recruiting: "İşe alım",
desktop: "Masaüstü",
indicators: "Göstergeler",
journal: "Dergi",
quit: "Çık",
branch_name: "Şube adı",
mfo: "İFC",
number: "Numara",
account: "Hesap",
card_number:"Kart numarası",
penalty_name: "Cezanın adı",
bonus_name: "Bonusun adı",
payment_date:"Ödeme tarihi",
adaptation: "Adaptasyon",
agreement_employees: "Sözleşmeler(ÇALIŞANLAR)",
login: "Giriş yap",
permission: "İzinler",
synchronization: "Senkronizasyon",
tests: "Testler",
wage: "Ücretler",
dafault: "Varsayılan",
real_amount:"Gerçek miktar",
bonus_amount: "Bonus tutarı",
payment_for:"Ücret ödemesi",
code:"Kod",
measurement_id:"Ölçüm",
measurement:"Ölçüm",
buy_price:"Satın alma fiyatı",
buy_price_currency_id:"Satın alma para birimi",
selling_price:"Satış fiyatı",
selling_price_currency_id:"Satış para birimi",
filials:"Kullanıcı şirketleri",
deal_id:"Anlaşma",
product_id:"Ürün",
price:"Fiyat",
currency_id:"Para birimi",
quantity:"Miktar",
discount:"İndirim",
nds:"KDV",
slug:"Sümüklü böcek",
file:"Dosya",
full_name:"Tam ad",
company_id:"Müşterinin şirketi",
date_of_birth:"Doğum tarihi",
passport_serial_number:"Pasaport seri numarası",
passport_validity:"Pasaportun geçerlilik süresi",
actual_address:"Adres",
ınn:"VKN",
gender_id:"Cinsiyet",
type:"Tip",
responsible:"Sorumlu",
country:"Ülke",
countries:"Ülkeler",
city:"Şehir",
cities:"Şehirler",
city_id:"Şehir",
country_id:"Ülke",
category:"Kategori",
category_id:"Kategori",
categories:"Kategoriler",
parent_id:"Ebeveyn",
product:"Ürün",
products:"Ürünler",
weekly:'Hafta',
monthly: 'Ay',
yearly: 'Yıl',
per: 'için',
is_super_admin: 'Süper yönetici',
new:"Yeni",
cancel:"İptal",
products:"Ürünler",
products:"Ürünler",
files:"Dosyalar",
profit:"Kar",
profit_in_percents:"Yüzde kar",
profit_in_money:"Para kazançları",
archivedDeals:"Arşivlenmiş anlaşmalar",
deletedDeals:"Uzak işlemler",
roles: "Roller",
permissions: "İzinler",
show: "Göster",
unzip: "Unzip",
restore: "Geri Yükle",
rate: "Kurs",
new_deal: "Yeni anlaşma",
new_task: "Yeni görev",
new_contact: "Yeni iletişim",
no_data_yet: "Henüz veri yok",
reasons: "Sebepler",
deal_reasons: "Anlaşmanın nedenleri",
reason: "Sebep",
clientType: "Müşteri türü",
clientTypes: "Müşteri türleri",
client_with_search: "Aranacak istemci türünü girin",
sale_by_period: "Döneme göre satış",
finish_date: "Gerçek bitiş tarihi",
check_list_id: "Liste",
is_done: "Bitti",
task_board_id: "Tahta",
task_id: "Görev",
task: "Görev",
archivedTasks: "Arşivlenmiş görevler",
deletedTasks: "Silinen görevler",
dealGoal: "Anlaşmanın amacı",
dealGoals: "Anlaşmanın amaçları",
year: "Yıl",
month: "Ay",
count: "Miktar",
by_count: "Sayıya göre",
by_money_amount: "bütçede",
by_profit: "karla",
color_id: "Renk",
dealCondition: "Ödeme koşulları",
dealConditions: "Ödeme Koşulları",
shipmentTerm: "Teslim şartları",
shipmentTerms: "Teslimat Şartları",
shipmentTerms: "Teslimat Şartları",
taskReason: "Görevin nedeni",
taskReasons: "Görev nedenleri",
payment_in_percents:"Yüzde olarak ödeme",
payment_in_money:"Parayla ödeme",
telegram_chat_id:"Telegram Sohbet Kimliği",
package:"Paketleme",
packages: "Paketler",
productSize:"Ürün boyutu",
productSizes:"Ürün boyutları",
productModel: "Model",
productModels: "Modeller",
is_default:"Varsayılan",
emails:"E-postalar",
email:"E-posta",
Unseen:"Okunmamış",
Seen:"Okundu",
Answered:"Yanıtlandı",
kpTemplate:"KP şablonu",
kpTemplates:"KP şablonları",
header:"Üst metin",
footer:"Alt metin",
columns:"Sütunlar",
sip_number:"İç numara",
sip_password:"Dahili numara şifresi",
sipAccount:"İç numara",
sipAccounts:"İç numaralar",
name_title: "İtiraz",
phone_number: "Telefon numarası",
website: "Web sitesi",
contact_type: "Kişi türü",
phoneContact: "İletişim",
phoneContacts: "Kişiler",
reception_address: "Resepsiyon odasının adresi",
control_user: "Kullanıcı denetimi",
user_permission_type: "Kullanıcı denetimi türü",
calendarNotifications: "Hatırlatıcılar",
calendarNotification: "Hatırlatıcılar",
title: "İsim",
server_url: "Sunucu adresi",
port: "Liman",
extension_type: "İç numara türü",
additional: "GELİŞMİŞ",
spectators: "Gözlemciler",
arayan: "Arayan",
called_to: "Alıcı",
billsec: "Zaman",
callStatus: "Durum",
calldate: "Tarih ve saat",
recordingfile: "Kayıt",
user_telegram_status: 'Telgraf durumu',
department_list: 'Bölümler',
department: 'Departman',
select_status: 'Durumu seç',
available: 'Kullanılabilir',
not_disturb: 'Rahatsız etmeyin',
busy: 'Meşgul',
absent: 'Yerinde değil',
invisible: 'Görünmez',
outgoing: 'Giden',
incoming: 'Gelen',
missed: 'Cevapsız',
deal_by_phone_number: 'Telefon numarasıyla anlaşma',
no_paymnets: 'Ödeme yok',
profit_m: 'Marj',
assign_role: 'Rol atama',
view: 'Gözetleme',
link: 'Bağlantı',
display_and_wallpaper: 'Ekran ve duvar kağıdı',
dynamic_wallpaper: 'Dinamik duvar kağıtları',
please_select: 'Lütfen seçin',
message: 'Mesaj',
more: 'Daha fazlası',
accepted: 'kabul edildi',
not_accepted: 'kabul edilmedi',
about_deal: 'ANLAŞMA HAKKINDA',
working: 'İşçi',
accounts: 'Hesaplar',
history: 'Tarihçe',
applications: 'Uygulamalar',
who_gave_the_task: 'Görevi kim verdi',
task_in_project: 'Proje (grup) içindeki görev',
do_you_really_want_to_do_this: 'Bunu gerçekten yapmak istiyor musunuz?',
deal_deleted: 'Anlaşma silindi',
warning: 'Uyarı',
operation_canceled: 'İşlem iptal edildi',
deal_restored: 'Anlaşma geri yüklendi',
participants: 'Üyeler',
no_deadline: 'Son tarih yok',


labels: 'Etiketler',
deadline: 'Son tarih',
restore_to_board: 'Tahtaya geri yükle',
delete_task: 'Görevi sil',
unable_to_delete_last_form: 'Son form silinemiyor',
'Enter your email address': 'E-posta adresinizi girin',
'Please enter your email correctly!': 'Postayı doğru şekilde girin !',
'You cannot add more than 5 email addresses!': "5'ten fazla e-posta adresi ekleyemezsiniz !",
'Problem checking connection, please try again': 'Bağlantıyı kontrol ederken sorun, tekrar deneyin',
'To test the connection, first enter your IMAP and SMTP details.': 'Bağlantıyı doğrulamak için önce IMAP ve SMTP bilgilerini girin.',
'Are you sure you want to activate the task ?': 'Görevi gerçekten etkinleştirmek istiyor musunuz?',
'Are you sure you want to activate the deal ?': 'Anlaşmayı gerçekten etkinleştirmek istiyor musunuz?',
completed_tasks: 'Tamamlanmış görevler',
confirm: 'Onayla',
day: 'gün',
today: 'bugün',
call_history: 'Arama geçmişi',
no_audio_file: 'Ses dosyası yok',
profit_sum: 'Marj tutarı',
corr_account: 'Corr. hesap',
delete_field: 'Alanları Sil',
add_field: 'Alan Ekle',
contact_person: 'İlgili kişi',
no_email: 'E-posta yok',
all_deals: 'Toplam işlemler',
succesfull: 'Başarılı olanlar',
succesfully: 'Başarılı',
fact: 'Gerçek',
difference: 'Fark',
active_d: 'Etkin',
all_tasks: 'Toplam görevler',
tasks: 'Görevler',
client_type: 'Müşteri türü',
no_task: 'Görev yok',
additional_client_data: 'Ek müşteri verileri',
individual_info: 'Bireysel bilgiler',
January: 'Ocak',
February: 'Şubat',
March: 'Mart',
April: 'Nisan',
May: 'Mayıs',
June: 'Haziran',
July: 'Temmuz',
August: 'Ağustos',
September: 'Eylül',
October: 'Ekim',
November: 'Kasım',
December: 'Aralık',
payment_start_date: 'Başlangıç tarihi ödeme tarihi',
payment_end_date: 'Tamamlanma tarihi ödeme tarihi',
delete_payment: 'Ödemeyi sil',
add_payment: 'Ödeme ekle',
client_wıth_contact: 'Temasla ilgili müşteri şirketleri',
loading: 'Yükle',
add_participant: 'Gözlemci ekle',
add: 'Ekle',
'Payment cannot be more than 100%, please correct before saving': "Ödeme% 100'den fazla olamaz, lütfen kaydetmeden önce düzeltin",
'One of the payments is 0, please enter the percentage for the existing payment first': "Ödemelerden biri 0' dır, lütfen önce mevcut ödemenin yüzdesini girin",
file_limit_warning: 'Sadece {number} dosyalarını seçebilirsiniz!',
file_size_limit_warning: "{number} MB'den küçük bir dosya yükleyin",
file_already_exists: "Bu dosya zaten var",
update_deal: 'Anlaşmayı düzenle',
'Payment cannot be more than 100%': "Ödeme% 100'den fazla olamaz",
'Do you want to send a message to the client': 'Müşteriye mesaj göndermek istiyorsun',
send: 'Gönder',
something_wrong_with_connection_of_email: 'E-postaya bağlanırken bir şeyler ters gitti, yeniden başlatmak için tekrar deneyin, E-posta düğmesine tekrar tıklayın.',
error_m: 'Hata',
'There is no email in the transaction to search for messages!': 'Anlaşmada mesaj aramak için e-posta yok !',
'Enter one from text or file': 'Metin veya dosyadan birini girin',
'Please select an email address if you would like to send an email': 'Bir e-posta göndermek isterseniz lütfen bir e-posta adresi seçin',
all_emails: 'Tüm e-postalar',
'Date difference should not be more than 4 days to search all email addresses !': 'Tüm e-posta adreslerini aramak için tarih farkı 4 günden fazla olmamalıdır!',
'The date difference should not be more than 10 days for the search!': 'Tarih farkı aramak için 10 günden fazla olmamalıdır!',
no_data_to_search: 'Aranacak tarih yok !',
no_emaıl_to_search_messages: 'İşlemde iletileri aramak için e-posta yok !',
no_selected_deal_to_save: 'Kaydedilecek seçili işlem yok !',
product_list_is_empty: 'Ürün listesi boş',
'Are you sure you want to uninstall the product?': 'Ürünü gerçekten kaldırmak istiyor musunuz?',
deleting_product_canceled: 'Ürün silme işlemi iptal edildi',
overall: 'Genel',
archivate_deal: 'Anlaşmayı arşivle',
delete_deal: 'Anlaşmayı sil',
offer: 'Öneriler',
add_task: 'Görev ekle',
kp: 'Ticari Teklif',
update_payment: 'Ödemeyi değiştir',
do_you_want_to_delete_participant: 'Gerçekten gözlemciyi kaldırmak istiyorsunuz',
do_you_want_to_archivate_task: 'Anlaşmayı gerçekten arşivlemek istiyor musunuz?',
archivating_completed: 'Arşivleme tamamlandı',
archivating_canceled: 'Arşivleme iptal edildi',
company_y: 'Şirket',
do_you_want_to_delete_board: 'Tahtayı gerçekten silmek istiyor musunuz?',
message_m: 'Mesajlar',
activating_deal_canceled: 'İşlemin etkinleştirilmesi iptal edildi',
activating_task_canceled: 'Görev etkinleştirmesi iptal edildi',
delete_completely: 'Kalıcı olarak sil',
please_enter_input: 'Lütfen {input} girin',
length_must_be: 'Uzunluk {number} olmalıdır',
length_must_be_min: 'Uzunluk en az {number} karakter olmalıdır',
length_must_be_max: 'Uzunluk maksimum {number} karakter olmalıdır ',
'Do you really want to import products from another project': 'Ürünleri gerçekten başka bir projeden içe aktarmak istiyorsunuz',
auto_lock_when_ınactive: 'Hareketsizlik durumunda otomatik kilitleme',
turned_off: 'Kapalı',
numner_of_secunds: '{number} saniye',
numner_of_minutes: '{number} dakika',
numner_of_hours: '{number} saat',
auto_lock: 'Otomatik kilitleme',
passcode: 'Şifre-şifre',
enter_your_passcode: 'Geçerli şifreyi girin',
enter_new_password: 'Yeni şifreyi girin',
repeat_passcode: 'Şifreyi tekrarla',
enter_your_password_again: 'Lütfen şifreyi tekrar girin',
password_does_not_match: 'Şifre eşleşmiyor!',
number_found_on_server: 'Sunucuda dahili numara bulundu',
check_if_number_exists_in_server: 'Sunucuda bir numara olup olmadığını kontrol et',
waiting_to_complete: 'beklemede',
delete_participant_from_list: 'Üye listesinden kaldır',
completed: 'tamamlandı',
check_task: 'doğrulama görevi',
in_process: 'devam ediyor',
task_checked: 'Görev doğrulandı',
ready_to_check: 'İncelemeye hazır',
add_description: 'Açıklama ekle',
add_item: 'Öğe ekle',
downloaded_files: 'İndirilen dosyalar',
recommended_settings: ' Öneri ayarları',
add_to_card: 'Karta ekle',
check_list: 'Kontrol listesi',
attechment: 'Ek',
add_to_completed_tasks: 'Tamamlanmış görevlere ekle',
operation_completed: 'İşlem tamamlandı',
adding_a_task_list: 'Görev listesi ekleme',
dates: 'Tarihler',
create_new_label: 'Yeni Etiket oluştur',
update_label: 'Etiketi düzenle',
without_deals: 'Anlaşma Yok',
please_select_input: 'Lütfen {input} öğesini seçin',
user_email: 'Kullanıcının e-postası',
email_password: 'E-posta şifresi',
smtp_server: 'SMTP sunucusu',
smtp_port: 'SMTP bağlantı noktası',
imap_server: 'IMAP sunucusu',
imap_port: 'IMAP bağlantı noktası',
pop3_server: 'POP3 sunucusu',
pop3_port: 'POP3 bağlantı noktası',
add_email: 'Posta ekle',
deal_c: 'fırsatlar',
overdue_tasks: 'Gecikmiş görevler',
completed_tasks_for_today: 'Bugün için tamamlanmış görevler ',
task_to_complete: 'Yapılacak görevler',
deal_source: 'Anlaşma kaynakları',
deals_by_managers: 'Yönetici işlemleri',
goals: 'Hedefler',
deals_by_country: 'Ülkeye göre işlemler',
latest_files: 'Son dosyalar',
no_files: 'Dosya yok',
deal_delete_reason: 'Anlaşmanın kaldırılmasının nedenleri',
payments_by_period: 'dönem ödemeleri',
by_days: 'Güne göre',
by_months: 'Aylara göre',
by_years: 'Yıllara göre',
selling_product_statistic: 'Ürün satış istatistikleri',
conter_agent: 'Karşı taraf',
top_deals: 'En iyi 5 başarılı anlaşma ',
overall: 'Genel',
total_amount_t: 'Toplam tutar',
sale: 'satış',
profit: 'kar',
no_deal: 'Anlaşma yok',
locations: 'Konumlar',
'A file with the same name already exists, choose another one or change the name': 'Bu ada sahip bir dosya zaten var, başka birini seçin veya adı değiştirin',
write_text: 'Metni yaz',
'There is a problem on the server side':  'Sunucu tarafında bir sorun var',
information: 'Bilgi',
no_phone: 'Numara yok',
enter_phone_number_to_call: 'Arayacağınız numarayı girin !',
'Are you sure to close this window': 'Bu pencereyi kapattığınızdan emin misiniz?',
phone_registered_message: 'Telefon {phone} kayıtlı !',
register: 'Kayıt',
phone_not_registered_message: 'Telefon {phone} kayıtlı değil sayfayı tekrar güncelleyin',
wiev_all: 'Hepsini gör',
cash_box: 'Kasiyer',
payments: 'Ödemeler',
payments_for_expenses: 'Masraflar için ödemeler',
calls: 'Aramalar',
counterparties: 'Karşı taraflar',
h_r: 'H/R',
product_settıngs: 'Ürün',
calendar: 'Takvim',
russia: 'Russia',
exıt: 'Çık',
deletion_reason: 'silme nedeni',
text_length_must_be: 'Uzunluk {min} ile {max} arasında olmalıdır',
'ANSWERED': "Yanıtlandı",
'NO ANSWER': "Yanıt yok",
'BUSY': "Meşgul",
'settings profile': 'Profil ayarları',
'sitting Company': 'Şirket Ayarları',
'logout': 'Çık',
'available': 'Kullanılabilir',
'invisible': 'Görünmez',
'Not Disturb': 'Rahatsız etmeyin',
'busy': 'Meşgul',
'absent': 'Yerinde değil',
"Amount without discount and NDS": "İndirim ve vergisiz tutar",
"Discount amount": "İndirim tutarı",
"NDSamount": "Vergi Tutarı",
"Total amount": "Toplam tutar",
prefix: 'Önek',
phoneNumberForSipAccountOutsideCalls: 'Giden aramalar için telefonlar',
phoneNumberForSipAccountOutsideCall: 'Giden aramalar için telefon',
clients: "Müşteriler",
phone_number_is_not_valid: 'Lütfen doğru numarayı arayın. Telefon numarası yanlış',
validation: 'Sağlama',
phones: 'Telefonlar',
add_participants: 'Üye ekle',
call: 'Aramalar',
you_can_not_call_yourself: 'Kendinizi arayamazsınız!',
caller_name: "Giden çağrının adı",
target_name: "Gelen aramanın adı",
invalid_phone_alert: "Telefon geçerli değil, kimlik bilgilerinizi kontrol edin ve tekrar deneyin!",
calling: "Çağrı",
home: "Ana",
goal_in: "Amaç",
task_map: "Görev haritası",
please_enter_phone_number: "Lütfen telefon numarasını arayın",
phone_already_exits: "Telefon zaten var, başka bir numarayı arayın",
unregister: "Kaydı iptal et",
phone_unregistered: "Telefon {phone} kayıtsız",
registered: "Kayıtlı",
unregistered: "Kayıtlı değil",
phone_not_registered_please_register_phone_to_make_a_call: 'Telefon kayıtlı değil lütfen aramak için telefonu kaydedin',
cash_register: 'Gişe',
payments: 'Ödemeler',
payment: 'Ödeme',
bankAccounts: 'Banka hesabı',
bankAccount: 'Banka hesabı',
paymentType: "Ödeme türü",
unpaidClientDeals: "Müşterinin ödenmemiş işlemleri",
pay_for_deals: "İşlemler için ödeme yapın",
paid_money: "Ödendi",
remainder: "Kalıntı",
insufficient_money: "Yeterli para yok, lütfen tekrar kontrol edin!",

}
