export const filter = {
  id : null,  
  cost_type_id: null, 
  payment_type_id: null, 
  bank_account_id: null, 
  money_amount: 0, 
  currency_rate: 0, 
  currency_id: null, 
  date: '', 
  date_interval: [], 
  comment: '', 
  created_at: '',
  updated_at: ''  
};
  