export const uz_messages = {
    yes: 'Ha',
    no: 'Yo\'q',
    n: "№",
    total_amount: "Umumiy summa",
    deals: "Bitimlar",            
    deal: "Bitim",
    create: "Yaratish",
    create_board: "Doska yaratish",
    update_board: "Doskani o\'zgartirish",
    update: "O\'zgartirish",
    delete: "O\'chirish",
    board_name: "Doska nomi",
    The_field_must_not_be_empty: "Maydon bo\'sh bo\'lishi mumkin emas",
    header_color: "Sarlavha rangi",
    New_header_color: "Yangi sarlavha rangi",
    See_board_name: "Doska nomini kiriting",
    New_board_name: "Yangi doska nomi",
    company: "Mijoz kompaniyasi",
    companies: "Mijozlar kompaniyalari",
    client: "Mijoz",
    user: "Foydalanuvchi",
    name: "Nomi",
    first_name: "Ism",
    surname: "Familiya",
    patronymic: "Sharifi",
    phone: "Telefon",
    gender: "Jinsi",
    email: "Elektron pochta",
    role_id: "Rol",
    nameFull: "Ismi",
    new_source: "Yangi manba",
    save: "Saqlash",
    close: "Yopish",
    search: "Qidirish",
    source: "Manba",
    sources: "Manbalar",
    text: "Xabar",
    text_t: "Matn",
    type_id: "Turi",
    settings: "Sozlamalar",
    color: "Rang",
    colors: "Ranglar",
    date: "Sana",
    status: "Holat",
    count_product: "Miqdori",
    total_price: "Jami summa",
    download: "Yuklab olish",
    filial: "Foydalanuvchi kompaniyasi",
    currency: "Valyuta",
    currencies: "Valyutalar",
    begin_date: "Boshlanish sanasi",
    end_date: "Tugash sanasi",
    without_finish_date: "Tugash sanasi belgilanmagan",
    deal_type: "Bitim turi",         
    dealStage: "Bitim bosqichi",
    comment: "Izoh",
    dealType: "Bitim turi",          
    dealTypes: 'Bitimlar turlari',
    created_at: "Yaratilgan sana",
    updated_at: "O\'zgartirilgan sana",
    symbol: "Belgi",
    edit_m: "{m}ni tahrirlash",
    new_m: "Yangi {m}",
    mfo: "МФО",
    okonx: "ОКОНХ",
    oked: "IFUT ",
    rkp_nds: "РКП НДС",
    work_phone: "Ish telefoni",
    work_place: "Ish joyi",
    legal_address:"Yuridik manzil",
    landmark: "Mo\'ljal (yuridik manzil)",
    residence_place:"Doimiy yashash manzili",
    costType: 'Xarajat turi',
    costTypes: 'Xarajatlar turlari',
    
    measurements: 'O\'lchovlar',
    dealStages: "Bitimlar bosqichlari",
    // mi
    patronymic:'Sharif',
    surname: 'Familiya',
    pin_code: 'PIN-kod',
    is_employee:'Xodim',
    //end
    nameGiven: "Nomi",
    nameFull: "Ismi",
    requirements: "Talablar",
    requirements_y: "Talablar",
    reason: "Sabab",
    reason_y: "Sabablar",
    priority: "Ustuvorligi",
    priority_y: "Ustuvorliklar",
    position:"Lavozim",
    position_p:"Lavozimlar",
    graphicType: "Grafik turi",
    graphicType_p: "Grafiklar turlari",
    company: "Mijoz tashkiloti",
    currency: "Valyuta",
    currency_y: "Valyuta",
    filial: "Foydalanuvchi kompaniyasi",
    filter: "Filtr",
    total_working_day: "Ish haftasi",
    graphic: "Ish grafiki",
    language_graphic_type: "Iltimos, nomini kiriting",
    employmentType : "Bandlik turi",
    province: "Shahar",
    region:"Shahar",
    region_: "Viloyat",
    vacancy:"Bo\'sh ish o\'rinlari",
    last_name:"Familiya",
    middle_name:"Sharifi",
    date_of_birth:"Tug'ilgan sanasi",
    phone:"Telefon",
    email:"Elektron pochta",
    status:"Holat",
    role:"Rol",
    phone_number_y:"Telefon",
    summary: "Rezyume",
    UploadFile: "Faylni yuklash",
    UploadFileText: "500 KB dan kichik jpg/png fayllari,",
    serial_number: "Seriya raqami",
    ip_address: "IP manzil",
    device:"Qurilmalar",
    device_y:"Qurilma",
    income: "Daromad darajasi",
    work_type: "Ishchining holati",
    district_id:"Tuman",
    district:"Tuman",
    nation:"Millat",
    nation_y:"Millat",
    code: "Kod",
    okurs: "Teskari kurs",
    profile: "Profil",
    dateAdoption: "Qabul qilish sanasi",
    staffTest: "Sinovdagi",
    operating: "Ishlayotgan",
    dismissed: "Bo'shatilgan",
    save: "Saqlash",
    save_and_exit: "Saqlash va chiqish",
    close: "Yopish",
    candidates: "Nomzodlar",
    find: "Qidirish",
    daily: "Har kuni",
    from: "dan",
    before: "gacha",
    dinner: "Tushlik",
    users: "Foydalanuvchilar",
    user: "Foydalanuvchi",
    user_id: "Foydalanuvchi",
    password: "Parol",
    new_password: "Yangi parol",
    current_password: "Hozirgi parol",
    confirm_password: "Parolni qayta tering",
    finance: "Moliya",
    money_amount: "Mablag'",
    access_rights: "Kirish huquqlari",
    active: "Faol",
    is_active: "Faol",
    inactive: "Faol emas",
    working_hours_report: "Ish vaqti bo\'yicha hisobot",
    age: "Yosh",
    nv_monitoring: "HV monitoringi",
    o_salary: "Maosh",
    new_incoming_payment: "Yangi kiruvchi to\'lov",
    incoming: "Kirim",
    outgoing: "Chiqim",
    employees: "Xodimlar",
    paymentTypes: "To\'lov turi",
    amount: "Mablag'",
    check: "Hisob",
    check_a: "Hisoblar",
    salary_type: "Maosh turi",
    salary: "Maosh",
    bank: "Bank",
    checking_account: "Hisob raqami",
    expiration_date: "Amal qilish muddati",
    comment: "Izoh",
    overtime: "Qo\'shimcha ish",
    total: "Jami:",
    being_late: "Kechikishlar",
    other_penalties: "Boshqa jarimalar",
    penalties: "Jarimalar",
    bonuses: "Bonuslar",
    on_hands: "Naqd",
    passport_number: "Pasport seriyasi",
    passport_issued_by: "Pasport kim tomonidan berilgan",
    passport_valid_until: "Pasport amal qilish muddati",
    inn: "STIR",
    job: "Ish",
    dashboard: "Boshqaruv paneli",
    hiring_date: "Ishga qabul qilish sanasi",
    manager: "Rahbar",
    allow_th_employee_to_access_the_system: "Xodimga tizimga kirishga ruxsat berish.",
    add_candidates: "Nomzod qo\'shish",
    description: "Tavsif",
    photo: "Foto",
    min: "minimal",
    max: "maksimal",
    recruiting: "Ishga qabul qilish",
    desktop: "Ish stoli",
    indicators: "Ko\'rsatkichlar",
    journal: "Jurnal",
    quit: "Chiqish",
    branch_name: "Bo\'lim nomi",
    mfo: "MFO",
    number: "Raqam",
    account: "Hisob",
    card_number:"Karta raqami",
    penalty_name: "Jarima nomi",
    bonus_name: "Bonus nomi",
    payment_date:"To\'lov sanasi",
    adaptation: "Adaptatsiya",
    agreement_employees: "Shartnomalar(Xodimlar)",
    login: "Kirish",
    permission: "Ruxsat",
    synchronization: "Sinxronlashtirish",
    tests: "Testlar",
    wage: "Maosh",
    dafault: "Standart",
    real_amount:"Haqiqiy miqdor",
    bonus_amount: "Bonus miqdori",
    payment_for:"Davr uchun to\'lov",
    code:"Kod",
    measurement_id:"O\'lchov",
    measurement:"O\'lchov",
    buy_price:"Xarid narxi",
    buy_price_currency_id:"Xarid valyutasi",
    selling_price:"Sotish narxi",
    selling_price_currency_id:"Sotish valyutasi",
    filials:"Foydalanuvchilar kompaniyalari",
    deal_id:"Bitim raqami",
    product_id:"Mahsulot",
    price:"Narx",
    currency_id:"Valyuta",
    quantity:"Miqdori",
    discount:"Chegirma",
    nds:"QQS",
    slug:"Слизняк",// нега керак
    file:"Fayl",
    full_name:"To\'liq ism",
    company_id:"Mijozning kompaniyasi",
    date_of_birth:"Tug\'ilgan sanasi",
    passport_serial_number:"Seriya raqami",
    passport_validity:"Pasport amal qilish muddati",
    actual_address:"Manzil",
    inn:"STIR",
    gender_id:"Jinsi",
    type:"Turi",
    responsible:"Mas\'ul",
    country:"Davlat",
    countries:"Davlatlar",
    city:"Shahar",
    cities:"Shaharlar",
    city_id:"Shahar",
    country_id:"Davlat",
    category:"Kategoriya",
    category_id:"Kategoriya",
    categories:"Kategoriyalar",
    parent_id:"Ota-onasi",
    product:"Mahsulot",
    products:"Mahsulotlar",
    weekly:'Hafta',
    monthly: 'Oy',
    yearly: 'Yil',
    per: ' uchun',
    is_super_admin: 'Super admin',
    new:"Yangi",
    cancel:"Bekor qilish",
    products:"Mahsulotlar",
    files:"Fayllar",
    profit:"Foyda",
    profit_in_percents:"Foyda foizda",
    profit_in_money:"Foyda pulda",
    archivedDeals:"Arxivlangan bitimlar",
    deletedDeals:"O\'chirilgan bitimlar",
    roles: "Rollar",
    permissions: "Kirish huquqlari",
    show: "Ko\'rsatish",
    unzip: "Arxivdan chiqarish",
    restore: "Qayta tiklash",
    rate: "Kurs",
    new_deal: "Yangi bitim",
    new_task: "Yangi vazifa",
    new_contact: "Yangi kontakt",
    no_data_yet: "Hozircha ma\'lumot yo\'q",
    reasons: "Sabablar",
    deal_reasons: "Bitim sabablari",
    reason: "Sabab",
    clientType: "Mijoz turi",
    clientTypes: "Mijozlar turlari",
    client_with_search: "Qidiruv uchun mijoz turini kiriting",
    sale_by_period: "Davr bo\'yicha sotuv",
    finish_date: "Yakunlanish sanasi",
    check_list_id: "Ro\'yxat",
    is_done: "Bajarildi",
    task_board_id: "Doska",
    board: "Doska",
    task_id: "Vazifa",
    task: "Vazifa",
    taskBig: "Barcha vazifalar",
    tasksMy: "Mening vazifalarim",
    archivedTasks: "Arxivlangan vazifalar",
    deletedTasks: "O\'chirilgan vazifalar",
    dealGoal: "Bitim maqsadi",
    dealGoals: "Bitimlar maqsadlari",
    year: "Yil",
    month: "Oy",
    count: "Miqdor",
    by_count: "Miqdor bo\'yicha",
    by_money_amount: "Mablag\' bo\'yicha",
    by_profit: "Foyda bo\'yicha",
    color_id: "Rang",
    dealCondition: "Bitim sharti",
    dealConditions: "Bitimlar shartlari",
    shipmentTerm: "Yetkazib berish sharti",
    shipmentTerms: "Yetkazib berish shartlari",
    taskReason: "Vazifa sababi",
    taskReasons: "Vazifalar sabablari",
    payment_in_percents:"To\'lov foizda",
    payment_in_money:"To\'lov pulda",
    telegram_chat_id:"Telegram chat identifikatori",
    package:"Qadoq",
    packages:"Qadoqlar",
    productSize:"Mahsulot o\'lchami",
    productSizes:"Mahsulot o\'lchamlari",
    productModel:"Model",
    productModels:"Modellar",
    is_default:"Standart",
    emails:"Elektron pochtalar",
    email:"Elektron pochta",
    Unseen:"O\'qilmagan",
    Seen:"O\'qilgan",
    Answered:"Javob berilgan",
    kpTemplate:"Tijoriy taklif namunasi",
    kpTemplates:"Tijoriy taklif namunalari",
    header:"Tepadagi kolontitul",
    footer:"Pasdagi kolontitul",
    columns:"Ustunlar",
    sip_number:"Ichki raqam",
    sip_password:"Ichki raqam paroli",
    sipAccount:"Ichki akkaunt",
    sipAccounts:"Ichki akkauntlar",
    name_title: "Ism",
    phone_number: "Telefon raqami",
    website: "Veb-sayt",
    contact_type: "Kontakt turi",
    phoneContact: "Kontakt",
    phoneContacts: "Kontaktlar",
    reception_address: "Qabulxona manzili",
    control_user: "Foydalanuvchi nazorati",
    user_permission_type: "Foydalanuvchi nazorati turi",
    calendarNotifications: "Eslatmalar",
    calendarNotification: "Eslatma",
    title: "Nomi",
    server_url: "Server manzili",
    port: "Port",
    extension_type: "Ichki raqam turi",
    additional: "Qo\'shimcha",
    spectators: "Kuzatuvchilar",
    caller: "Chiquvchi qo\'ng\'iroq",
    called_to: "Kiruvchi qo\'ng\'iroq",
    billsec: "Vaqt",
    callStatus: "Holat",
    calldate: "Sana va vaqti",
    recordingfile: "Yozib olish",
    user_telegram_status: 'Telegram holati',
    department_list: 'Bo\'limlar',
    department: 'Bo\'lim',
    select_status: 'Holatni tanlang',
    available: 'Mavjud',
    not_disturb: 'Bezovta qilinmasin',
    busy: 'Band',
    absent: 'Joyida yo\'q',
    invisible: 'Ko\'rinmas',
    outgoing: 'Chiquvchi',
    incoming: 'Kiruvchi',
    missed: 'O\'tkazib yuborilgan',
    deal_by_phone_number: 'Telefon raqamiga ko\'ra bitim',
    no_paymnets: 'To\'lovlar yo\'q',
    profit_m: 'Daromad',
    assign_role: 'Rolni belgilash',
    view: 'Ko\'rish',
    link: 'Havola',
    display_and_wallpaper: 'Ekran va fon',
    dynamic_wallpaper: 'Dinamik fon',
    please_select: 'Iltimos, tanlang',
    message: 'Xabar',
    more: 'Ko\'proq',
    accepted: 'Qabul qilindi',
    not_accepted: 'Qabul qilinmadi',
    about_deal: 'Bitim haqida',
    working: 'Ishchi',
    accounts: 'Hisoblar',
    history: 'Tarix',
    applications: 'Ilovalar',
    who_gave_the_task: 'Kim vazifa berdi',
    task_in_project: 'Loyihadagi vazifa (guruhga)',
    do_you_really_want_to_do_this: 'Aniq bu amalni bajarishni xohlaysizmi?',
    deal_deleted: 'Bitim o\'chirildi',
    warning: 'Ogohlantirish',
    operation_canceled: 'Amal bekor qilindi',
    deal_restored: 'Bitim qaytarildi',
    participants: 'Qatnashchilar',
    no_deadline: 'Muddati yo\'q',
    
    
     labels: 'Belgilаr',
    deadline: 'Muddati',
    restore_to_board: 'Doskaga qaytarish',
    delete_task: 'Vazifani o\'chirish',
    unable_to_delete_last_form: 'Oxirgi formani o\'chirib bo\'lmaydi',
    'Enter your email address': 'Elektron pochtagizni kiriting',
    'Please enter your email correctly!': 'Iltimos, elektron pochtangizni to\'g\'ri kiriting!',
    'You cannot add more than 5 email addresses!': '5 tadan ortiq elektron pochta manzili qo\'shib bo\'lmaydi!',
    'Problem checking connection, please try again': 'Ulanishni tekshirishda muammo, yana urinib ko\'ring',
    'To test the connection, first enter your IMAP and SMTP details.': 'Ulanishni tekshirish uchun avval IMAP va SMTP ma\'lumotlarini kiriting.',
    'Are you sure you want to activate the task ?': 'Vazifani faollashtirishga ishonchingiz komilmi?',
    'Are you sure you want to activate the deal ?': 'Bitimni faollashtirishga ishonchingiz komilmi?',
    completed_tasks: 'Bajarilgan vazifalar',
    confirm: 'Tasdiqlash',
    day: 'kun',
    today: 'bugun',
    call_history: 'Qo\'ng\'iroqlar tarixi',
    no_audio_file: 'Audio fayli yo\'q',
    profit_sum: 'Foyda summasi',
    corr_account: 'Korr. hisob',
    delete_field: 'Maydonni o\'chirish',
    add_field: 'Maydon qo\'shish',
    contact_person: 'Kontakt shaxsi',
    no_email: 'Elektron pochta yo\'q',
    all_deals: 'Barcha bitimlar',
    succesfull: 'Muaffaqiyatlilar',
    succesfully: 'Muaffaqiyatli',
    fact: 'Fakt',
    difference: 'Farqi',
    active_d: 'Faol',
    all_tasks: 'Barcha vazifalar',
    tasks: 'Vazifalar',
    client_type: 'Mijozlar turlari',
    no_task: 'Vazifa yo\'q',
    additional_client_data: 'Mijozning qo\'shimcha ma\'lumotlari',
    individual_info: 'Jismoniy shaxs haqida ma\'lumot',
    January: 'Yanvar',
    February: 'Fevral',
    March: 'Mart',
    April: 'Aprel',
    May: 'May',
    June: 'Iyun',
    July: 'Iyul',
    August: 'Avgust',
    September: 'Sentabr',
    October: 'Oktabr',
    November: 'Noyabr',
    December: 'Dekabr',
    payment_start_date: 'To\'lov boshlanish sanasi',
    payment_end_date: 'To\'lov tugash sanasi',
    delete_payment: 'To\'lovni o\'chirish',
    add_payment: 'To\'lov qo\'shish',
    client_with_contact: 'Kontakt bilan bog\'liq mijoz kompaniyalari',
    loading: 'Yuklash',
    add_participant: 'Kuzatuvchi qo\'shish',
    add: 'Qo\'shish',
    'Payment cannot be more than 100%, please correct before saving': 'To\'lov 100% dan ko\'p bo\'lishi mumkin emas. Iltimos, saqlashdan oldin to\'g\'irlang',
    'One of the payments is 0, please enter the percentage for the existing payment first': 'To\'lovlardan biri 0 ga teng. Iltimos, oldin mavjud to\'lov uchun foizni kiriting',
    file_limit_warning: 'Faqat {number} fayllarni tanlash mumkin!',
    file_size_limit_warning: 'Hajmi {number} MBdan kichik bo\'lgan faylni yuklang',
    file_already_exists: 'Bu fayl allaqachon mavjud',
    update_deal: 'Bitimni o\'zgartirish',
    'Payment cannot be more than 100%': 'To\'lov 100% dan ko\'p bo\'la olmaydi',
    'Do you want to send a message to the client': 'Mijozga xabar yuborishni xohlaysizmi?',
    send: 'Yuborish',
    something_wrong_with_connection_of_email: 'Nimadir elektron pochta ulanishida noto\'g‘ri ketdi, qaytadan sinab ko\'ring, qayta yuklash uchun "Elektron pochta" tugmasini bosing.',
    error_m: 'Xatolik',
    'There is no email in the transaction to search for messages!': 'Xabarlarni qidirish uchun tranzaksiyada elektron pochta yo\'q!',
    'Enter one from text or file': 'Matn yoki fayldan birini kiriting',
    'Please select an email address if you would like to send an email': 'Agar elektron pochta yuborishni xohlaysiz, elektron pochta manzilini tanlang',
    all_emails: 'Barcha elektron pochta manzillari',
    'Date difference should not be more than 4 days to search all email addresses!': 'Barcha elektron pochta manzillari bo\'yicha qidirish uchun sanalar orasidagi farq 4 kundan oshmasligi kerak!',
    'The date difference should not be more than 10 days for the search!': 'Qidirish uchun sanalar orasidagi farq 10 kundan oshmasligi kerak!',
    no_data_to_search: 'Qidirish uchun ma\`lumot yo\'q!',
    no_email_to_search_messages: 'Xabarlarni qidirish uchun kelishuvda elektron pochta yo\'q!',
    no_selected_deal_to_save: 'Saqlash uchun tanlangan bitimlar yo\'q!',
    product_list_is_empty: 'Mahsulotlar ro\'yxati bo\'sh',
    'Are you sure you want to uninstall the product?': 'Mahsulotni o\'chirmoqchimisiz?',
    deleting_product_canceled: 'Mahsulotni o\'chirish bekor qilindi',
    overall: 'Jami',
    archivate_deal: 'Bitimni arxivlash',
    delete_deal: 'Bitimni o\'chirish',
    offer: 'Takliflar',
    add_task: 'Vazifa qo\'shish',
    kp: 'TT',
    update_payment: 'To\'lovni o\'zgartirish',
    do_you_want_to_delete_participant: 'Kuzatuvchini o\'chirishni xohlaysizmi?',
    do_you_want_to_archivate_task: 'Vazifani arxivlashni xohlaysizmi?',
    archivating_completed: 'Arxivlash yakunlandi',
    archivating_canceled: 'Arxivlash bekor qilindi',
    company_y: 'Kompaniya',
    do_you_want_to_delete_board: 'Doskani o\'chirishni xohlaysizmi?',
    message_m: 'Xabarlar',
    activating_deal_canceled: 'Bitimni faollashtirish bekor qilindi',
    activating_task_canceled: 'Vazifani faollashtirish bekor qilindi',
    delete_completely: 'Butunlay o\'chirish',
    please_enter_input: 'Iltimos, {input}ni kiriting',
    length_must_be: 'Uzunligi {number} bo\'lishi kerak',
    length_must_be_min: 'Minimal uzunlik {number} belgi bo\'lishi kerak',
    length_must_be_max: 'Maksimal uzunlik {number} belgi bo\'lishi kerak',
    'Do you really want to import products from another project': 'Boshqa loyihadan mahsulotlarni import qilmoqchimisiz?',
    auto_lock_when_inactive: 'Faollik bo\'lmaganda avtomatik bloklash',
    turned_off: 'O\'chirildi',
    numner_of_secunds: '{number} soniya',
    numner_of_minutes: '{number} daqiqa',
    numner_of_hours: '{number} soat',
    auto_lock: 'Avtomatik bloklash',
    passcode: 'Parol',
    enter_your_passcode: 'Joriy parolingizni kiriting',
    enter_new_password: 'Yangi parolni kiriting',
    repeat_passcode: 'Parolni takrorlang',
    enter_your_password_again: 'Iltimos, parolni yana bir marotaba kiriting',
    password_does_not_match: 'Parol mos kelmayapti!',
    number_found_on_server: 'Ichki raqam serverda topildi',
    check_if_number_exists_in_server: 'Ichki raqam serverda mavjudligini tekshirish',
    waiting_to_complete: 'Bajarilishi kutilmoqda',
    delete_participant_from_list: 'Ishtirokchilar ro\'yxatidan o\'chirish',
    completed: 'Bajarildi',
    check_task: 'Tekshiruvga vazifa',
    in_process: 'Jarayonda',
    task_checked: 'Vazifa tekshirildi',
    ready_to_check: 'Tekshirishga tayyor',
    add_description: 'Tavsif qo\'shish',
    add_item: 'Element qo\'shish',
    downloaded_files: 'Yuklangan fayllar',
    recommended_settings: 'Tavsiya etish sozlamalari',
    add_to_card: 'Kartaga qo\'shish',
    check_list: 'Tekshirish ro\'yxati',
    attechment: 'Ilova',
    add_to_completed_tasks: 'Bajarilgan vazifalarga qo\'shish',
    operation_completed: 'Amal bajarildi',
    adding_a_task_list: 'Vazifalar ro\'yxatini qo\'shish',
    dates: 'Sanalar',
    create_new_label: 'Yangi belgi yaratish',
    update_label: 'Belgini o\'zgartirish',
    without_deals: 'Bitimlar yo\'q',
    please_select_input: 'Iltimos, {input}ni tanlang',
    user_email: 'Foydalanuvchi elektron pochta manzili',
    email_password: 'Elektron pochtasi paroli',
    smtp_server: 'SMTP server',
    smtp_port: 'SMTP port',
    imap_server: 'IMAP server',
    imap_port: 'IMAP port',
    pop3_server: 'POP3 server',
    pop3_port: 'POP3 port',
    add_email: 'Pochta qo\'shish',
    deal_c: 'Bitimlar',
    overdue_tasks: 'Muddati o\'tgan vazifalar',
    completed_tasks_for_today: 'Bugungi bajarilgan vazifalar',
    task_to_complete: 'Bajarilishi kerak bo\'lgan vazifalar',
    deal_source: 'Bitim manbalari',
    deals_by_managers: 'Menejerlar kesimida bitimlar',
    goals: 'Maqsadlar',
    deals_by_country: 'Davlatlar kesimida bitimlar',
    latest_files: 'So\'nggi fayllar',
    no_files: 'Fayllar yo\'q',
    deal_delete_reason: 'Bitimlar o\'chirilish sabablari',
    payments_by_period: 'Davrlar bo\'yicha to\'lovlar',
    by_days: 'Kunlar bo\'yicha',
    by_months: 'Oylar bo\'yicha',
    by_years: 'Yillar bo\'yicha',
    selling_product_statistic: 'Mahsulotlar sotish statistikasi',
    conter_agent: 'Kontragent',
    top_deals: 'Top 5 muvaffaqiyatli bitimlar',
    overall: 'Jami',
    total_amount_t: 'Umumiy summa',
    sale: 'Sotuv',
    profit: 'Foyda',
    no_deal: 'Bitim yo\'q',
    locations: 'Joylashuvlar',
    'A file with the same name already exists, choose another one or change the name': 'Bunday nomli fayl allaqachon mavjud, boshqa nom tanlang yoki nomini almashtiring',
    write_text: 'Matn yozing',
    'There is a problem on the server side': 'Server tomonida muammo mavjud',
    information: 'Ma\'lumot',
    no_phone: 'Telefon yo\'q',
    enter_phone_number_to_call: 'Qo\'ng\'iroq qilish uchun telefon raqamini kiriting!',
    'Are you sure to close this window': 'Siz haqiqatan ham bu oynani yopishni istaysizmi?',
    phone_registered_message: '{phone} telefon ro\'yxatga olingan!',
    register: 'Ro\'yxatga olish',
    phone_not_registered_message: '{phone} telefon ro\'yxatga olinmagan, sahifani yangilang',
    wiev_all: 'Barchasini ko\'rish',
    cash_box: 'Kassa',
    payments: 'To\'lovlar',
    payments_for_expenses: 'Xarajatlar uchun to\'lovlar',
    calls: 'Qo\'ng\'iroqlar',
    counterparties: 'Kontragentlar',
    h_r: 'HR',
    product_settings: 'Mahsulot sozlamalari',
    calendar: 'Taqvim',
    russia: 'Rossiya',
    exit: 'Chiqish',
    deletion_reason: 'o\'chirish sababi',
    text_length_must_be: 'Uzunligi {min} dan {max} gacha bo\'lishi kerak',
    'ANSWERED': "Javob berildi",
    'NO ANSWER': "Javob berilmadi",
    'BUSY': "Band",
    'settings profile': 'Profil sozlamalari',
    'settingCompany': 'Kompaniya sozlamalari',
    'logout': 'Chiqish',
    'available': 'Mavjud',
    'invisable': 'Ko\'rinmas',
    'NotDisturb': 'Bezovta qilinmasin',
    'busy': 'Band',
    'absent': 'Joyida emas',
    "Amount without discount and NDS": "Chegirma va soliqlarsiz miqdori",
    "Discount amount": "Chegirma miqdori",
    "NDS amount": "Soliq miqdori",
    "Total amount": "Umumiy summa",
    prefix: 'Prefiks',
    phoneNumberForSipAccountOutsideCalls: 'Chiqish qo\'ng\'iroqlari uchun telefon raqami',
    phoneNumberForSipAccountOutsideCall: 'Chiqish qo\'ng\'irog\'i uchun telefon raqami',
    clients: "Mijozlar",
    phone_number_is_not_valid: 'Ushbu telefon raqami noto\'g\'ri. Iltimos, to\'g\'ri raqam kiriting',
    validation: 'Tekshiruv',
    phones: 'Telefonlar',
    add_participants: 'Ishtirokchilar qo\'shish',
    call: 'Qo\'ng\'iroq',
    you_can_not_call_yourself: 'Siz o\'zingizga qo\'ng\'iroq qila olmaysiz!',
    caller_name: "Qo\'ng'iroq qiluvchi ismi", 
    target_name: "Qabul qiluvchi ismi",
    invalid_phone_alert: "Ushbu telefon raqami noto\'g\'ri, ma'lumotlarni tekshirib, qaytadan urunib ko\'ring!",
    calling: "Qo\'ng'iroq qilish",
    home: "Bosh sahifa",
    goal_in: "Maqsad",
    task_map: "Vazifalar xaritasi",
    please_enter_phone_number: "Iltimos, telefon raqamini kiriting",
    phone_already_exits: "Ushbu telefon raqami allaqachon mavjud, boshqa raqam kiriting",
    unregister: "Ro\'yxatdan chiqarish",
    phone_unregistered: "{phone} telefon ro\'yxatga olinmagan",
    registered: "Ro\'yxatga olingan",
    unregistered: "Ro\'yxatga olinmagan",
    phone_not_registered_please_register_phone_to_make_a_call: 'Ushbu telefon raqami ro\'yxatga olinmagan. Qo\'ng\'iroq qilish uchun telefonni ro\'yxatga oling',
    cash_register: 'Kassa',
    payments: 'To\'lovlar',
    payment: 'To\'lov',
    bankAccounts: 'Bank hisob raqamlari',
    bankAccount: 'Bank hisob raqami',
    paymentType: "To\'lov turi",
    unpaidClientDeals: "To\'lov qilinmagan mijozlar bilan bitimlar",
    pay_for_deals: "Bitimlar uchun to\'lov",
    paid_money: "To\'langan",
    remainder: "Qoldiq",
    insufficient_money: "Pul yetarli emas. Iltimos, qaytadan tekshiring!",

    // not translated to turkish

    paying_money: "Quyidagi to\'lov",
    client_balance: "Mijoz balansi",
    remainder_for_client_balance: "Mijoz balansi uchun qoldiq",
    enter_money_correctly: "Pul miqdorini to\'g\'ri kiriting",
    payment_from_client_balance: "Mijoz balansidan to\'lov",
    no_payments_to_deals: "Bitimlar uchun to\'lovlar yo\'q",
    payemnts_for_costs: 'Xarajatlar uchun to\'lovlar',
    outgoing_cost: 'Chiquvchi to\'lov',
    outgoing_costs: 'Chiquvchi to\'lovlar',
    outgoing_money: 'Chiquvchi summa',
    measurement_short: "O\'lchov",
    calculate_money: "{m} bo\'yicha pulni hisoblash",
    service: "Xizmat",
    services: "Xizmatlar",
    paid_money_will_be_changed_are_you_sure: "Bitim uchun to\'langan pul o\'zgaradi, yangilamoqchimisiz?",
    exhibition: 'Ko\'rgazma',
    exhibitions: 'Ko\'rgazmalar',
    exhibitionType: 'Ko\'rgazma turi',
    exhibitionTypes: 'Ko\'rgazmalar turlari',
    expenses: 'Xarajatlar',
    to: 'gacha',
    remaining: 'Qoldi',
    managers: 'Menejerlar',
    add_managers: 'Menejerlarni qo\'shish',
    happyCall: 'Xursand qo\'ng\'iroq',
    happyCalls: 'Xursand qo\'ng\'iroqlar',
    happyCallQuestion: 'Telefon orqali savol',
    happyCallQuestions: 'Telefon orqali savollar',
    happyCallAnswer: 'Xursand javob',
    happyCallAnswers: 'Xursand javoblar',
    questions: 'Savollar',
    archived: 'Arxivlangan',
    rating: 'Baho',
    childProducts: 'Mahsulotlar',
    waste: 'Yo\'qotishlar',
    auto_calculate: 'Avtomatik hisoblash',
    auto: 'Avtomatik',
    manual: 'Qo\'lda kiritish',
    excel: 'Excel',
    set_cover: 'Biriktirish',
    remove_cover: 'Biriktirilganni olib tashlash',
    attachments: 'Qo\'shimcha',
    new_list: 'Yangi vazifalar ro\'yxati',
    others: 'Boshqalar',
    please_input: 'Iltimos, kiriting',
    image: "Rasm",
    provider: "Ta'minotchi",
    provider_price_list: "Ta\'minotchi narxlari ro\'yxati",
    service_provider: 'Ta\'minotchi xizmatlari',
    'services do not have': 'Hali xizmatlar yo\'q',
    notificationTypes: 'Xabarlar turlari',
    notificationType: 'Xabar turi',
    all_users: 'Barcha foydalanuvchilar',
    notification: 'Tizim xabari',
    notifications: 'Tizim xabarlar',
    activity_time: 'Faollik vaqti',
    review: 'Ko\'rib chiqish',
    reviewed: 'Ko\'rib chiqildi',
    not_reviewed: 'Ko\'rib chiqilmadi',
    reviewed_date: 'Ko\'rib chiqish sanasi',
    notifications_nn: 'Xabarlar',
    all_notifications: 'Barcha xabarlar',
    queues: 'Navbatda',
    days: 'kun',
    typeOfProjectWorks: 'Loyiha ishining turi',
    projects: 'Loyihalar',
    project: 'Loyiha',
    project_name: 'Loyiha nomi',
    'project without a deadline': 'Muddatsiz loyiha',
    term: 'Muddat',
    start: 'Boshlanishi',
    finish: 'Tugashi',
    plan: 'Reja',
    hours: 'Soatlar',
    project_manager: 'Loyiha rahbari',
    type_of_project_work: 'Loyiha ishining turi',
    next: 'Keyingi',
    prev: 'Oldingi',
    notifationTypes: 'Xabarlar turlari',
    new_y: 'Yangilar',
    for_checking: 'Tekshirish uchun',
    completed_y: 'Bajarilganlar',
    progress: 'Progress',
    no_tasks: 'Vazifalar yo\'q',
    step_number: 'Qadam {number}',
    project_color: 'Loyiha rangi',
    created: 'Yaratilgan',
    overdue: 'Muddati o\'tgan',
    meeting_time: 'Uchrashuv vaqti',
    in_an_hour: 'bir soat oldin',
    in_two_hours: 'ikki soat oldin',
    in_three_hours: 'uch soat oldin',
    in_four_hours: 'to\'rt soat oldin',
    per_day: 'bir kun oldin',
    creator: 'Yaratuvchi',
    managerTypes: 'Menejerlar turlari',
    bonuses_manager_type: 'Menejer turi uchun bonuslar',
    'bonuses of manager type for first deal': 'Birinchi bitim uchun menejer turi bonuslari',
    profit_user: 'Foydalanuvchining daromadi',
    content: 'Mazmun',
    set_product_price: "Mahsulot narxini belgilash",
    client_name: 'Mijoz ismi',
    individual: 'Jismoniy shaxs',
    legal: 'Yuridik shaxs',
    required: 'Majburiy',
    not_required: 'Majburiy emas',
    production: 'Ishlab chiqarish',
    productionable: 'Ishlab chiqarish uchun',
    ready: 'Tayyor',
    product_type: 'Mahsulot turi',
    'Верхний текст': 'Tepadagi kolontitul',
    'Нижний текст': 'Pasdagi kolontitul',
    'Содержание': 'Mazmun',
    'label': 'Nomi',
    'Shipping address': 'Yangi maydon',
    'Rule': 'To\'ldirilishi majburiy', 
    'Required': 'To\'ldirilishi majburiy', 
    'Type': 'Turi', 
    'Input': 'Kiritish', 
    'InputNumber': 'Son kiritish', 
    'Select': 'Variantlar', 
    'Radio': 'Yagona tanlov', 
    'Checkbox': 'Bir nechta tanlov', 
    'Switch': 'O\'zgartirgich', 
    'DatePicker': 'Sana', 
    'addNewOption': 'Variant qo\'shish', 
    'Simple Form': 'Oddiy forma', 
    'Deal  Form': 'Bitimli forma', 
     
        
    }
    